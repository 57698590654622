<template>
    <b-container>
        <b-card class="text-center">
            <h2>
                <font-awesome-icon
                    icon="calendar-alt"
                    class="mr-2"
                ></font-awesome-icon>
                Events
            </h2>
            <div class="mt-3">
                <button
                    class="sc-btn pill"
                    @click="addEvent()"
                >
                    <font-awesome-icon icon="plus-circle" class="mr-1"></font-awesome-icon>
                    Add Event
                </button>
            </div>
        </b-card>
        <b-skeleton-wrapper class="mt-2" :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>
            <b-list-group class="mt-2">
                <b-list-group-item
                    v-for="(event, idx) in events"
                    :key="idx"
                >
                    <b-badge
                        v-if="!event.registration_open && !event.user_registered"
                        variant="danger"
                        class="float-right"
                    >
                        <font-awesome-icon icon="exclamation-circle"></font-awesome-icon> Registration closed
                    </b-badge>
                    <router-link
                        :to="{ name: 'event_settings', params: { id: event.id } }"       
                    >
                        <h5>
                            <em v-if="!event.title.length">No title.</em>
                            <span v-else>{{ event.title }}</span>
                        </h5>
                    </router-link>
                    <p>{{ event.description || "No description." }}</p>
                    <div v-if="event.event_type == 1">
                        <b>Tickets Left: </b>
                        <b-badge :variant="(event.tickets_left) ? 'secondary' : 'danger'">{{ event.tickets_left }}</b-badge>
                    </div>
                    <div class="text-center">
                        <router-link
                            is="b-button"
                            variant="secondary"
                            class="mr-2"
                            :to="{ name: 'event_settings', params: { id: event.id } }"
                        >
                            <font-awesome-icon icon="cog"></font-awesome-icon>
                        </router-link>
                        <b-button
                            @click="cloneEvent(event.id)"
                            variant="primary"
                            class="mr-2"
                        >
                            <font-awesome-icon icon="clone"></font-awesome-icon>
                        </b-button>
                        <b-button
                            @click="confirmDelete(event)"
                            variant="danger"
                        >
                            <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                        </b-button>
                    </div>
                </b-list-group-item>
            </b-list-group>
        </b-skeleton-wrapper>
        <!--
        <b-table
            id="event-table"
            class="mt-2"
            :busy="busy"
            :fields="fields"
            :items="eventList"
            head-variant="dark"
            bordered
        >
            <template #cell(title)="row">
                <b-badge
                    v-if="!row.item.registration_open && !row.item.user_registered"
                    variant="danger"
                    class="float-right"
                >
                    <font-awesome-icon icon="exclamation-circle"></font-awesome-icon> Registration closed
                </b-badge>
                <router-link
                    :to="{ name: 'event_settings', params: { id: row.item.id } }"       
                >
                    <h5>
                        <em v-if="!row.value.length">No title.</em>
                        <span v-else>{{ row.value }}</span>
                    </h5>
                </router-link>
                <p>{{ row.item.description || "No description." }}</p>
                <div v-if="row.item.event_type == 1">
                    <b>Tickets Left: </b>
                    <b-badge :variant="(row.item.tickets_left) ? 'secondary' : 'danger'">{{ row.item.tickets_left }}</b-badge>
                </div>
            </template>
            <template #cell(actions)="row">
                <div class="float-right">
                    <router-link
                        is="b-button"
                        variant="secondary"
                        class="mr-2"
                        :to="{ name: 'event_settings', params: { id: row.item.id } }"
                    >
                        <font-awesome-icon icon="cog"></font-awesome-icon>
                    </router-link>
                    <b-button
                        @click="cloneEvent(row.item.id)"
                        variant="primary"
                        class="mr-2"
                    >
                        <font-awesome-icon icon="clone"></font-awesome-icon>
                    </b-button>
                    <b-button
                        @click="confirmDelete(row.item)"
                        variant="danger"
                    >
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </b-button>
                </div>
            </template>
        </b-table>-->
    </b-container>
</template>
<script>
export default {
    data () {
        return {
            busy    : false,
            loading : false,
            events  : [],
            fields  : [
                        { key: "title",         label: "Event"  },
                        { key: "date_modified", label: "Last Modified"},
                        { key: "actions" }
                    ]
        };
    },
    mounted() {
        this.getEventList();
    },
    methods: {
        getEventList() {
            this.loading = true;
            this.$api.get("/api/events/admin/list").then((response) => {
                this.events  = response.data.events;
                this.loading = false;
            }).catch(() => {
                this.events = [];
                this.loading = false;
            });
        },
        eventList() {
            return  this.$api.get("/api/events/admin/list").then((response) => {
                        return response.data.events;
                    }).catch(() => {
                        return [];
                    });
        },
        addEvent() {
            return this.$api.post("/api/events/admin/create").then((response) => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.$router.push({ name: "event_settings", params: { id: response.data.event_id } });
                //this.$root.$emit("bv::refresh::table", "event-table");
            });
        },
        confirmDelete(event) {
            this.$bvModal.msgBoxConfirm("Please confirm that you want to delete the event '" + event.title + ".'", {
                title           : "Please Confirm",
                size            : "sm",
                buttonSize      : "sm",
                okVariant       : "danger",
                okTitle         : "Delete",
                cancelTitle     : "Cancel",
                footerClass     : "p-2",
                hideHeaderClose : false,
                centered        : true
            })
            .then(deleteConfirmed => {
                if (deleteConfirmed) {
                    this.deleteEvent(event.id);
                }
            })
            .catch(err => {
                console.log("Error:", err);
            });
        },
        deleteEvent(event_id) {
            this.$api.get(`/api/events/admin/${event_id}/remove`).then(response => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.getEventList();
                // this.$root.$emit("bv::refresh::table", "event-table");
            });
        },
        cloneEvent(event_id) {
            this.$api.get(`/api/events/admin/${event_id}/clone`).then(response => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.getEventList();
                // this.$root.$emit("bv::refresh::table", "event-table");
            });
        }
    }
}
</script>